import { defineStore, } from 'pinia'
import { ref, } from 'vue'

const useBannerStore = defineStore(
    'banner',
    () => {
        const colorBanner = ref<'primary'| 'success' | 'danger' | 'workshop'>('primary')
        const textBanner = ref<string>('')
        const isActiveBanner = ref<boolean>(false)
        const isBannerModeWorkshop = ref<boolean>(false)
        const isBannerModeWorkshopClick = ref<boolean>(false)

        function activeBannerEndTest () {
            isBannerModeWorkshop.value = false
            colorBanner.value = 'success'
            isActiveBanner.value = true
        }

        function activeBannermodeWorkshop () {
            colorBanner.value = 'workshop'
            isBannerModeWorkshop.value = true
            isActiveBanner.value = true
        }

        function activeBannerErrorData () {
            isBannerModeWorkshop.value = false
            colorBanner.value = 'danger'
            textBanner.value = 'En raison de données corrompues, la continuation de l\'installation a échoué. Veuillez relancer l\'installation.'
            isActiveBanner.value = true
        }

        const $reset = () => {
            colorBanner.value = 'primary'
            textBanner.value = ''
            isActiveBanner.value = false
            isBannerModeWorkshop.value = false
            isBannerModeWorkshopClick.value = false
        }

        return {
            colorBanner,
            textBanner,
            isActiveBanner,
            isBannerModeWorkshop,
            isBannerModeWorkshopClick,
            activeBannerEndTest,
            activeBannermodeWorkshop,
            activeBannerErrorData,
            $reset,
        }
    },
    {
        persist: false,
    }
)

export default useBannerStore
