<template>
    Freshmile Check v{{ version }} © Freshmile SAS, 2024
</template>

<script setup lang="ts">
import { isProduction, } from '@/constants/env'

const version = isProduction
    ? import.meta.env.VITE_APP_VERSION
    : import.meta.env.VITE_APP_RELEASE
</script>
