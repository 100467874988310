import { useRouter, } from 'vue-router'

import { resetAllStoresExpectedUser, } from '@/services/storeService'
import useUserStore from '@/stores/User'

const useResetInstall = () => {
    const userStore = useUserStore()
    const router = useRouter()

    const handleResetInstall = () => {
        userStore.isWorkshop = false
        resetAllStoresExpectedUser()
        router.push('/test-mode-selection')
    }

    return {
        handleResetInstall,
    }
}

export default useResetInstall
