<template>
    <o-sidebar
        data-testid="sidebar"
        :fullheight="true"
        :contentClass="sidebarClasses"
        :canCancel="false"
        @mouseenter="isExtended = true"
        @mouseleave="isExtended = false"
        open
    >
        <div
            class="mt-5"
            :class="{ 'is-align-self-flex-end mr-4': isExtended }"
        >
            <o-icon
                data-testid="sidebar-close-icon"
                v-if="isExtended"
                class="icon-close-open"
                icon="angle-left"
                @click="isExtended = false"
            />
            <o-icon
                data-testid="sidebar-angle-right-icon"
                v-else
                icon="angle-right"
            />
        </div>

        <step-progress-bar
            :isExtended="isExtended"
        />

        <div
            class="mt-auto mb-1"
        >
            <o-switch
                data-testid="afficher-explication-switch"
                v-if="isExtended"
                v-model="isSwitched"
                class="mb-4"
                variant="success"
            >
                Afficher toutes les explications
            </o-switch>

            <template v-if="stepsStore.isDisplayButtonAddComment">
                <f-button
                    data-testid="add-comment-button"
                    v-if="isExtended"
                    icon="life-ring"
                    :text="textButtonComment"
                    type="primary"
                    @click="isModalReportProblemActive = true"
                />
                <!--
                    FButton with icon and empty text have bad design (it's a bug in o-button of oruga),
                    so we create a custom div with the same design
                -->
                <div
                    v-else
                    class="f-button button is-primary"
                >
                    <o-icon
                        icon="life-ring"
                        variant="white"
                    />
                </div>
            </template>

            <div
                v-if="isExtended"
                class="version-number"
            >
                <version-number />
            </div>
        </div>
    </o-sidebar>

    <modal-report-problem
        :isModalActive="isModalReportProblemActive"
        @close="isModalReportProblemActive = false"
        @okClick="isModalReportProblemActive = false"
    />
</template>

<script setup lang="ts">
import { computed, ref, watch, } from 'vue'

import ModalReportProblem from '@/components/modals/ModalReportProblem.vue'
import StepProgressBar from '@/components/sidebar/StepProgressBar.vue'
import FButton from '@/components/ui/FButton.vue'
import VersionNumber from '@/components/ui/VersionNumber.vue'

import useStepsStore from '@/stores/steps'
import useUserStore from '@/stores/User'

interface SideBarProps {
    isMobile: boolean
}

const props = withDefaults(defineProps<SideBarProps>(), {
    isMobile: false,
})

const userStore = useUserStore()
const stepsStore = useStepsStore()
// eslint-disable-next-line vue/no-setup-props-destructure
const isExtended = ref(props.isMobile)
const isSwitched = ref(true)
const isModalReportProblemActive = ref(false)

const sidebarClasses = computed(() => (isExtended.value
    ? 'pl-5 custom-sidebar-large'
    : 'is-align-items-center custom-sidebar-small'))

const textButtonComment = computed(() => (isExtended.value
    ? 'Envoyer un commentaire'
    : ''))

watch(isSwitched, (newValue) => {
    userStore.isHelpActive = newValue
})

const emit = defineEmits(['sidebarExtendedClose'])

watch(isExtended, (newIsExtended) => {
    if (!newIsExtended) emit('sidebarExtendedClose')
})
</script>

<style scoped lang="scss">
.icon-close-open {
    cursor: pointer;
}

.version-number {
    font-size: 0.7rem;
}
</style>
