import { defineStore, } from 'pinia'
import { ref, reactive, } from 'vue'

import type { CbidFormatted, } from '@/components/types/CbidFormatted.d'
import type { LanCbidTest, } from '@/components/types/LanCbidTest.d'
import type { LanCbidTestName, } from '@/components/types/LanCbidTestName.d'

import { getCbidsFormatted, } from '@/services/cbidService'

const useDashboardStore = defineStore(
    'dashboard',
    () => {
        const cbidsFormatted = ref<CbidFormatted[]>([])
        const search = ref<string>('')
        const checkedState = reactive({} as Record<string, boolean>)
        const isTestsLoading = ref<boolean>(false)
        const isTestsSucced = ref<boolean>(false)

        function resetCheckedState () {
            cbidsFormatted.value.forEach((cbid) => {
                checkedState[cbid.cbid.chargeboxid] = false
            })
        }

        function initialize () {
            cbidsFormatted.value = getCbidsFormatted()
            resetCheckedState()
        }

        function getIndexCbidFromChargeboxid (chargeboxid: string) {
            return cbidsFormatted.value.findIndex((cbid) => cbid.cbid.chargeboxid === chargeboxid)
        }

        function locateTest (chargeboxid: string, testType: LanCbidTestName) {
            const indexCbid = getIndexCbidFromChargeboxid(chargeboxid)
            if (indexCbid === -1)
                return null

            const indexTest = cbidsFormatted.value[indexCbid].tests.findIndex((test: LanCbidTest) => test.test_name === testType)
            if (indexTest === -1)
                return null

            return { indexCbid, indexTest, }
        }

        function testPutIsLoading (chargeboxid: string, testType: LanCbidTestName, value: boolean) {
            const loc = locateTest(chargeboxid, testType)
            if (!loc)
                return

            cbidsFormatted.value[loc.indexCbid].tests[loc.indexTest].is_loading = value
        }

        function testPutResult (chargeboxid: string, test: LanCbidTest) {
            const loc = locateTest(chargeboxid, test.test_name)
            if (!loc)
                return

            cbidsFormatted.value[loc.indexCbid].tests[loc.indexTest] = test
        }

        const $reset = () => {
            cbidsFormatted.value = []
            search.value = ''
            Object.keys(checkedState).forEach((key) => {
                delete checkedState[key]
            })
            isTestsLoading.value = false
            isTestsSucced.value = false
        }

        return {
            cbidsFormatted,
            search,
            checkedState,
            isTestsLoading,
            isTestsSucced,
            initialize,
            resetCheckedState,
            testPutIsLoading,
            testPutResult,
            $reset,
        }
    },
    {
        persist: false,
    }
)

export default useDashboardStore
